<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumbDetail">
      <el-button type="primary" @click="gobackDetail">返回</el-button>
    </div>

    <!--主体内容区-->
    <div class="content">
      <div class="depoErrorDetail">
        <el-form label-position="left" :model="formInline" :ref="'form'">
          <div style="border: 1px solid #C0CCDA;border-radius: 4px;padding:15px;">
            <h2 class="title">
              <div class="title_icon"></div>
              <span>数据传输记录详情</span>
            </h2>
            <div style="padding-left:20px;">
              <el-form-item label="规则名称:">
                <span>{{formInline.edrsRuleName}}</span>
              </el-form-item>
              <el-form-item label="规则标签:">
                <span>{{formInline.tag}}</span>
              </el-form-item>
              <el-form-item label="状态:">
                <span>{{formInline.statusDesc}}</span>
              </el-form-item>
              <el-form-item label="最大重试次数:">
                <span>{{formInline.retryCount}}</span>
              </el-form-item>
              <el-form-item label="业务数据:">
                <span>{{formInline.body}}</span>
              </el-form-item>
               <el-form-item label="创建时间:">
                <span>{{formInline.createdTime}}</span>
              </el-form-item>
              <el-form-item label="返回结果:">
                <span>{{formInline.remark}}</span>
              </el-form-item>
              <el-form-item label="更新时间:">
                <span>{{formInline.updatedTime}}</span>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "depoErrorDetail",
  data() {
    return {
      formInline: {},
    };
  },
  components: {},
  created() {
    this.searParkRecordDetail(this.$route.query.edrsRecordId);
  },
  mounted() {},
  methods: {
    gobackDetail() {
      this.$router.go(-1);
    },
    searParkRecordDetail(item) {
      this.$axios
        .get("/acb/2.0/edrsRecord/" + item)
        .then((res) => {
          if (res.state == 0) {
            this.formInline = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
};
</script><!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumbDetail {
  text-align: right;
  height: 55px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
}

#addTicket {
  cursor: pointer;
  font-size: 40px;
  color: #0f6eff;
  display: inline-block;
  vertical-align: top;
  margin-top: -3px;
}

.marginSty {
  margin-bottom: 20px;
}

.span_pic {
  color: #606266;
}

.pic {
  display: inline-block;
  width: auto;
  height: 240px;
  vertical-align: top;
  margin-right 20px;
  border: 1px solid #eee;
}
</style>
<style>
.depoErrorDetail .el-form-item {
  margin-bottom: 0px;
}
</style>
